<template>
  <div id="module-create">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="confirmAlert"
          >
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required field
                  </p>
                </v-col>

                <v-col cols="12" sm="6" lg="4" md="4">
                  <h6><span class="text-danger">*</span>Select Year</h6>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="JciYearCode"
                    :rules="JciYearCodeRules"
                    :items="JciYearCodeOptions"
                    :loading="JciYearCodeOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" md="4">
                  <h6><span class="text-danger">*</span> Select Zone</h6>
                  <v-autocomplete
                    @change="getTableTypeOptions"
                    :reduce="(option) => option.value"
                    :items="ZoneCodeOptions"
                    :rules="ZoneCodeRules"
                    v-model="ZoneCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" lg="4" md="4">
                  <h6><span class="text-danger">*</span> Select Region</h6>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="RegionOptions"
                    :rules="RegionRules"
                    v-model="Region"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" lg="4" md="4">
                  <h6><span class="text-danger">*</span> Select Table Type</h6>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="TableTypeOptions"
                    :rules="TableTypeRules"
                    v-model="TableType"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" lg="4" md="4">
                  <h6><span class="text-danger">*</span> Table Name</h6>
                  <v-text-field
                    v-model="TableName"
                    placeholder="Table Name"
                    :rules="TableNameRules"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6><span class="text-danger">*</span>Select State</h6>
                  </label>
                  <v-autocomplete
                    @change="getDistrictCodeOptions"
                    :reduce="(option) => option.value"
                    :loading="StatesCodeOptionsLoading"
                    :items="StatesCodeOptions"
                    :rules="StatesCodeRules"
                    v-model="StatesCode"
                    required
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6><span class="text-danger">*</span>Select District</h6>
                  </label>
                  <v-autocomplete
                    @change="getCityCodeOptions"
                    :reduce="(option) => option.value"
                    :loading="DistrictCodeOptionsLoading"
                    :items="DistrictCodeOptions"
                    :rules="DistrictCodeRules"
                    v-model="DistrictCode"
                    required
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" lg="4" md="4">
                  <label>
                    <h6><span class="text-danger">*</span>Select City</h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :loading="CityCodeOptionsLoading"
                    :items="CityCodeOptions"
                    :rules="CityCodeRules"
                    v-model="CityCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <label>
                    <h6><span class="text-danger">*</span>Launch Date</h6>
                  </label>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        required
                        v-model="LaunchDate"
                        label="Launch Date"
                        hint="Select a Launch Date"
                        persistent-hint
                        readonly
                        v-on="on"
                        background-color="#F3F6F9"
                        class="form-control form-control-lg form-control-solid"
                        validate-on-blur
                        solo
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="LaunchDate"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="confirmAlert"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="#1db954"
                    class="font-size-h6 px-6 mr-3 white--text"
                  >
                    Submit
                  </v-btn>
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="#a52a2a"
                    class="font-size-h6 px-6 mr-3 white--text"
                  >
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  mixins: [common],
  components: {
    Cropper,
    CircleStencil,
  },
  props: {
    editRecordPrompt: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,

      UploadFlag: false,
      ResultFlag: false,

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      RegionRules: [(v) => !!v || "Region is required"],
      Region: "",
      RegionOptions: [],
      RegionOptionsLoading: false,

      TableTypeRules: [(v) => !!v || "Table Type is required"],
      TableType: "",
      TableTypeOptions: [],
      TableTypeOptionsLoading: false,

      TableNameRules: [(v) => !!v || "Table Name is required"],
      TableName: "",

      StatesCodeRules: [(v) => !!v || "States is required"],
      StatesCode: "",
      StatesCodeOptions: [],
      StatesCodeOptionsLoading: false,

      DistrictCodeRules: [(v) => !!v || "District is required"],
      DistrictCode: "",
      DistrictCodeOptions: [],
      DistrictCodeOptionsLoading: false,

      CityCodeRules: [(v) => !!v || "City is required"],
      CityCode: "",
      CityCodeOptions: [],
      CityCodeOptionsLoading: false,

      LaunchDate: "",
      LaunchDateRules: [(v) => !!v || "Launch date is required"],
      menu1: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var todayDate = new Date().toISOString().slice(0, 10);
        console.log(todayDate);

        this.LaunchDate = todayDate;
        this.pageData();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.JciYearCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    TableTypeOptions: function () {
      console.log("watch TableTypeOptions");
      this.TableTypeOptionsLoading = false;
    },
    StatesCodeOptions: function () {
      console.log("watch StatesCodeOptions");
      this.StatesCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
    DistrictCodeOptions: function () {
      console.log("watch DistrictCodeOptions");
      this.DistrictCodeOptionsLoading = false;
    },
    CityCodeOptions: function () {
      console.log("watch CityCodeOptions");
      this.CityCodeOptionsLoading = false;
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_table",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.LoadingFlag = false;
    },
    pageData() {
      this.getYearCodeOptions();
      this.getZoneCodeOptions();
      this.getRegionOptions();
      this.getStatesCodeOptions();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt", this.ResultFlag);
    },
    getYearCodeOptions() {
      console.log("getYearCodeOptions called");
      var CurrentYearId = this.YearCode;
      console.log("CurrentYearId=" + CurrentYearId);
      this.JciYearCodeOptionsLoading = true;
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.CurrentYearId;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getRegionOptions() {
      console.log("getRegionOptions called");
      var selectbox1_source = "Region";
      var selectbox1_destination = "RegionOptions";
      var selectbox1_url = "api/jcom/region/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getTableTypeOptions() {
      console.log("getTableTypeOptions called");
      var selectbox1_source = "TableType";
      var selectbox1_destination = "TableTypeOptions";
      var selectbox1_url = "api/jcom/table-type/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getStatesCodeOptions() {
      console.log("getStatesCodeOptions called");
      this.StatesCodeOptionsLoading = true;
      this.StatesCode = "";
      this.DistrictCode = "";
      var selectbox1_source = "StatesCode";
      var selectbox1_destination = "StatesCodeOptions";
      var selectbox1_url = "api/states/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getDistrictCodeOptions() {
      console.log("getDistrictCodeOptions called");
      this.DistrictCodeOptionsLoading = true;
      var StatesCode = this.StatesCode;
      this.DistrictCode = "";
      console.log("StatesCode=" + StatesCode);
      if (StatesCode != "") {
        var selectbox1_source = "DistrictCode";
        var selectbox1_destination = "DistrictCodeOptions";
        var selectbox1_url = "api/district/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          StatesCode: StatesCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "State should not be empty";
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getCityCodeOptions() {
      console.log("getCityCodeOptions called");
      var DistrictCode = this.DistrictCode;
      console.log("DistrictCode=" + DistrictCode);
      if (DistrictCode != "") {
        var selectbox1_source = "CityCode";
        var selectbox1_destination = "CityCodeOptions";
        var selectbox1_url = "api/city-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          DistrictCode: DistrictCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "District should not be empty";
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        // this.toast("error", "Kindly fill the required fields", true);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var UploadFlag = this.UploadFlag;
      console.log("UploadFlag=" + UploadFlag);

      this.progessStart = 1;
      this.SubmitFlag = true;
      this.ResultFlag = false;

      var ZoneCode = this.ZoneCode;
      var Region = this.Region;
      var CurrentYearId = this.JciYearCode;
      var LaunchDate = this.LaunchDate;
      var TableType = this.TableType;
      var TableName = this.TableName;
      var CityCode = this.CityCode;
      var LaunchDate = this.LaunchDate;

      var upload = {
        Year: CurrentYearId,
        Zone: ZoneCode,
        Region: Region,
        TableType: TableType,
        TableName: TableName,
        City: CityCode,
        LaunchedDate: LaunchDate,
      };
      console.log("upload=" + JSON.stringify(upload));

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;

      var add_url = server_url + "api/jcom/table/store";
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      this.SubmitFlag = true;

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SubmitFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);
          records = response.data.records;
          console.log({ records });

          if (flag == 1) {
            thisIns.sweetAlert("success", output, true);
            thisIns.ResultFlag = true;
            thisIns.closePrompt();
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.SubmitFlag = false;
        });
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#module-create {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>