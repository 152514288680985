<template>
  <div id="jcom-table-preview">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="confirmAlert"
          >
            <v-container>
              <v-row wrap>
                <v-col cols="12" sm="6" lg="3" md="4">
                  <h6>JCOM Table Year</h6>
                  <h5 style="color: blueviolet;">{{ YearName }}</h5>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="4">
                  <h6>JCOM Table Zone</h6>
                  <h5 style="color: blueviolet;">{{ ZoneName }}</h5>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="4">
                  <h6>JCOM Table Type</h6>
                  <h5 style="color: blueviolet;">{{ TableTypeName }}</h5>
                </v-col>
                <v-col cols="12" sm="12" lg="3" md="4">
                  <h6>JCOM Table Name</h6>
                  <h5 style="color: blueviolet;">{{ JcomTableName }}</h5>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="4">
                  <h6>JCOM Table City</h6>
                  <h5 style="color: blueviolet;">{{ CityName }}</h5>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <h6>JCOM Launch Date</h6>
                  <h5 style="color: blueviolet;">{{ LaunchDateTxt }}</h5>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <h6>JCOM Table Active Status</h6>
                  <v-chip
                    :color="getActiveStatusColor(ActiveStatusTxt)"
                    draggable
                    dark
                    ><h5>{{ ActiveStatusTxt }}</h5></v-chip
                  >
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="#a52a2a"
                    class="font-size-h6 px-6 mr-3 white--text"
                  >
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>
    
    <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  mixins: [common],
  components: {
    Cropper,
    CircleStencil,
  },
  props: {
    previewRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,

      UploadFlag: false,
      ResultFlag: false,

      YearName: "",
      ZoneName: "",
      TableTypeName: "",
      JcomTableName: "",
      CityName: "",
      LaunchDateTxt: "",
      ActiveStatusTxt: "",

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      TableTypeRules: [(v) => !!v || "Table Type is required"],
      TableType: "",
      TableTypeOptions: [],
      TableTypeOptionsLoading: false,

      TableId: 0,

      TableNameRules: [(v) => !!v || "Table Name is required"],
      TableName: "",

      CityIdRules: [(v) => !!v || "City is required"],
      CityId: "",
      CityIdOptions: [],
      CityIdOptionsLoading: false,

      LaunchDate: "",
      LaunchDateRules: [(v) => !!v || "Launch date is required"],
      menu1: false,

      ActiveStatus: 1,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var todayDate = new Date().toISOString().slice(0, 10);
        console.log(todayDate);

        this.LaunchDate = todayDate;
        this.pageData();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.JciYearCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    TableTypeOptions: function () {
      console.log("watch TableTypeOptions");
      this.TableTypeOptionsLoading = false;
    },
    CityIdOptions: function () {
      console.log("watch CityIdOptions");
      this.CityIdOptionsLoading = false;
    },
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_table",
        Action: "preview",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.LoadingFlag = false;

      var recordData = this.recordData;
      console.log("recordData = " + JSON.stringify(recordData));
      this.getTableRecords();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt");
    },
    getTableRecords() {
      console.log("getTableRecords called");
      this.YearName = this.recordData.YearName;
      this.ZoneName = this.recordData.ZoneName;
      this.TableTypeName = this.recordData.TableTypeName;
      this.JcomTableName = this.recordData.JcomTableName;
      this.CityName = this.recordData.CityName;
      this.LaunchDateTxt = this.recordData.LaunchDateTxt;
      this.ActiveStatusTxt = this.recordData.ActiveStatusTxt;
      this.TableId = this.recordData.JcomTableId;
    },
  },

  beforeMount() {
    this.refreshPageData();
  },
};
</script>
    <style lang="scss">
#module-create {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>